
















































































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import paymentsModule from '@/store/modules/Payments/module';
import OffCanvas from '@/commoncomponents/OffCanvas.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import { DropdownListItem, DropdownListOptions } from '@/ui-components/dropdownListBx/types';
import { PaymentPlanForm, PaymentPlan, PaymentPlanInterval, PaymentPlanFrequency, PaymentPlanConfiguration, StateModalType } from '@/Model/payments/types';
import { VeeValidateProviderMode } from '@/Model/forms/types';
import ProgressButton from '@/commoncomponents/progressButton/progressButton';
import APP_UTILITIES from '@/utilities/commonFunctions';
import StateModal from '@/popupcomponents/StateModal.vue';
import { ToastType } from '@/Model/toastType';

@Component({
  components: {
    OffCanvas,
    ValidationObserver,
    ValidationProvider,
    DropdownList,
    ProgressButton,
    StateModal
  }
})

export default class BillingMethodConfiguration extends Vue {
  readonly screenText = new ScreenText();
  readonly MAX_NUMBER_OF_PAYMENTS = 12;
  readonly MAX_PAYMENT_PLANS = 2;

  @Ref('formObserverRef')
  readonly formObserverRef?: InstanceType<typeof ValidationObserver>;

  isSaveDisabled = false;
  SELECT_TYPE_VALUE = -1;
  minimumOrderAmount:number | null = null;
  disablePaymentsCount:number | null = null;
  isPaymentPlanUnselected = false;
  isModalVisible = false;
  modalConfig = {
    title: 'Change your payment plans',
    description: 'Are you sure you want to change your payment plan settings? This may impact the application experience. Previously submitted applications will not be affected.',
    type: StateModalType.WARNING,
    cancelButtonLabel: this.screenText.getScreenText('BTN_GO_BACK_CAPS'),
    confirmButtonLabel: this.screenText.getScreenText('BTN_SAVE')
  };


  numberOfPaymentOptions: DropdownListItem[] = Array.from({ length: this.MAX_NUMBER_OF_PAYMENTS - 1 }, (_, index) => ({
    id: index + 2,
    value: (index + 2).toString()
  }));

  paymentOptionList = [{ id: this.SELECT_TYPE_VALUE, value: 'Select' }, ...this.numberOfPaymentOptions];

  frequencyOptionList: DropdownListItem[] = [
    { id: this.SELECT_TYPE_VALUE, value: 'Select' },
    { id: PaymentPlanFrequency.WEEKLY, value: 'Weekly'},
    { id: PaymentPlanFrequency.BIWEELKY, value: 'Bi-weekly'},
    { id: PaymentPlanFrequency.MONTHLY, 'value': 'Monthly'}
  ];

  paymentPlanDeactivationOptions: DropdownListItem[] = [
    { id: PaymentPlanInterval.DAYS, value: 'Days before session starts' },
    { id: PaymentPlanInterval.WEEKS, value: 'Weeks before session starts' },
    { id: PaymentPlanInterval.MONTHS, value: 'Months before session starts' }
  ];

  discountRuleListDropdown: DropdownListOptions = this.initializeDropdownListOptions(this.paymentOptionList);
  frequencyListDropdown: DropdownListOptions = this.initializeDropdownListOptions(this.frequencyOptionList);
  paymentPlanDeactivationDropdown: DropdownListOptions = this.initializeDropdownListOptions(this.paymentPlanDeactivationOptions);

  paymentPlanOptionList: PaymentPlanForm[] = [
    {
      id: 1,
      numberOfPaymentsList: {...this.discountRuleListDropdown},
      frequencyList: {...this.frequencyListDropdown},
      selectedValue: {
        numberOfPayments: this.paymentOptionList[0].id,
        frequency: this.frequencyOptionList[0].id,
        isItDuplicated: false
      }
    }
  ];
  currentDeactivationPeriodSelected: DropdownListItem = this.paymentPlanDeactivationOptions[0];
  validationMode = VeeValidateProviderMode;

  initializeDropdownListOptions(optionList: { id: number; value: string }[]): DropdownListOptions {
    return {
      id: optionList[0].id,
      value: optionList[0].value,
      singleSelect: true,
      showSelectLabel: false,
      dropdownList: optionList
    };
  }

  initPaymentPlanDeactivationConfig(optionList: DropdownListItem[], disablePaymentsInterval: number): {options: DropdownListOptions; selectedValue: DropdownListItem} {

    const selectedValue = optionList.find((option) =>  option.id === disablePaymentsInterval) as DropdownListItem;

    const options = {
      id: selectedValue.id,
      value: selectedValue.value,
      singleSelect: true,
      showSelectLabel: false,
      dropdownList: optionList
    };
    const dropdownOptions: {options: DropdownListOptions; selectedValue: DropdownListItem} = { options, selectedValue } ;

    return dropdownOptions;
  }

  initPaymentOptionListConfig(paymentPlanConfiguration: PaymentPlanConfiguration[]): PaymentPlanForm[]{
    return paymentPlanConfiguration.map((config:PaymentPlanConfiguration, index:number) =>{ 
      const selectedPaymentList = this.paymentOptionList.find((option) =>  option.id === config.paymentIntervalCount) as DropdownListItem;
      const selectedFrequencyList = this.frequencyOptionList.find((option) =>  option.id === config.paymentInterval) as DropdownListItem;
      return ({
        id: index + 1,
        numberOfPaymentsList: {...this.discountRuleListDropdown, id: selectedPaymentList.id, value: selectedPaymentList.value},
        frequencyList: {...this.frequencyListDropdown, id: selectedFrequencyList.id, value: selectedFrequencyList.value},
        selectedValue: {
          numberOfPayments: selectedPaymentList.id,
          frequency: selectedFrequencyList.id,
          isItDuplicated: false,
          existingPaymentPlanId: config.id
        }
      });
    }) as PaymentPlanForm[];
  
  }
  
  
  @Watch('isBillingMethodMenuOpenComputed', { deep: true })
  isDiscountMenuOpen(isMenuOpen: boolean): void {
    const paymentPlan = paymentsModule.paymentPlan;
    if (isMenuOpen && paymentPlan !== null) {
      this.minimumOrderAmount = paymentPlan.minimumOrderAmount;
      this.disablePaymentsCount = paymentPlan.disablePaymentsCount;

      const paymentPlanDeactivationConfig = this.initPaymentPlanDeactivationConfig(this.paymentPlanDeactivationOptions, paymentPlan.disablePaymentsInterval);
      
      this.paymentPlanDeactivationDropdown =  paymentPlanDeactivationConfig.options;
      this.currentDeactivationPeriodSelected = paymentPlanDeactivationConfig.selectedValue;
      this.paymentPlanOptionList = this.initPaymentOptionListConfig(paymentPlan.paymentPlanConfigurations);
    } 
  
  }

  /**
   * Resets the input fields to their initial state.
   * It resets minimum order amount, disable payment count, and dropdown selections.
   * Also resets the form validation state.
   * @private
   */
  private resetInputs(): void {
    this.minimumOrderAmount = null;
    this.disablePaymentsCount = null;
    //TODO: in edition mode we should use the "id" coming from the endpoint where the payment plan is retrieved
    this.paymentPlanOptionList = [
      {
        id: 1,
        numberOfPaymentsList: {...this.discountRuleListDropdown},
        frequencyList: {...this.frequencyListDropdown},
        selectedValue: {
          numberOfPayments: this.paymentOptionList[0].id,
          frequency: this.frequencyOptionList[0].id,
          isItDuplicated: false
        }
      }
    ];

    // reset form logic to prevent unnecesary error messages
    if (this.formObserverRef) {
      this.formObserverRef.reset();
      this.formObserverRef.flags.touched = false;
    }
  }

  toggleConfirmationModal() {
    this.isModalVisible = !this.isModalVisible;
  }


  /**
   * Confirmation logic when editing a discount
   */
  handleConfirm(): void {

    const paymentPlan = paymentsModule.paymentPlan;

    if (paymentPlan && paymentPlan != null) {
      const paymentPlanPayload = {
        id: paymentPlan.id,
        accountId: this.getAccountId(),
        minimumOrderAmount: this.minimumOrderAmount,
        disablePaymentsCount: this.disablePaymentsCount,
        disablePaymentsInterval: this.currentDeactivationPeriodSelected.id,
        paymentPlanConfigurations: this.paymentPlanOptionList.map((paymentPlanItem) => ({
          id: paymentPlanItem.selectedValue.existingPaymentPlanId || null,
          paymentPlanID: paymentPlanItem.id,
          paymentIntervalCount: paymentPlanItem.selectedValue.numberOfPayments,
          paymentInterval: paymentPlanItem.selectedValue.frequency,
        })),
        isActive: paymentPlan.isActive
      };

      this.onSubmission(paymentsModule.updatePaymentPlan(paymentPlanPayload));
      this.toggleConfirmationModal();

    }
  }

  async onSubmission(submit: Promise<void>) {
    try {
      this.isSaveDisabled = true;
      await submit;
      this.onClose();
    }
    catch (error) {
      console.error('Error on payment plan configuration submission:', error);
      APP_UTILITIES.showToastMessage('Unexpected Error!', ToastType.Error);
    }
    finally {
      this.isSaveDisabled = false;
    }

  }


  /**
   * Getter for whether the configuration discount menu is open.
   * @returns {boolean} Returns true if the billing method menu is open, false otherwise.
   */
  get isBillingMethodMenuOpenComputed(): boolean {
    return paymentsModule.isBillingMethodMenuOpen;
  }

  /**
   * Setter for whether the configuration discount menu is open.
   * @param {boolean} value - The new value indicating whether the billing method menu is open.
   */
  set isBillingMethodMenuOpenComputed(value: boolean) {
    paymentsModule.setIsConfiguratioDiscountMenuOpen(value);
  }

  /**
   * Getter for the visibility of the "Add Payment Plan" button.
   * @returns {boolean} Returns true if the payment plan list has fewer items than the maximum allowed.
   */
  get isAddPaymentPlanButtonVisible(): boolean {
    return this.paymentPlanOptionList.length < this.MAX_PAYMENT_PLANS;
  }

  /**
   * Handles the action to close the OffCanvas component.
   * Resets the input fields and updates the billing method menu state.
   */
  onClose(): void {
    paymentsModule.setIsBillingMethodMenuOpen(false);
    this.resetInputs();
  }

  /**
   * Handles changes in the selected payment plan options.
   * Updates the selected number of payments or frequency for a payment plan
   * and checks for duplicated payment plan configurations.
   * @param {DropdownListItem} selectedValue - The selected value from the dropdown list.
   * @param {number} paymentPlanId - The ID of the payment plan being updated.
   * @param {'numberOfPayments' | 'frequency'} paymentPlanKey - The key for the value being updated (either number of payments or frequency).
   */
  handlePaymentPlanOptionChange(selectedValue: DropdownListItem, paymentPlanId: number, paymentPlanKey: 'numberOfPayments' | 'frequency'): void {
    this.isPaymentPlanUnselected = false;
    const selectedPaymentPlanIndex = this.paymentPlanOptionList.findIndex(plan => plan.id === paymentPlanId);

    if (selectedPaymentPlanIndex !== -1) {
      this.paymentPlanOptionList[selectedPaymentPlanIndex].selectedValue[paymentPlanKey] = selectedValue.id;

      const filteredPlanList = this.paymentPlanOptionList.filter((planItem) => planItem.id !== paymentPlanId);

      if (filteredPlanList.length) {
        const firstKeyMatch = filteredPlanList.some((planItem) => planItem.selectedValue[paymentPlanKey] === selectedValue.id);
        const otherKeyMatch = paymentPlanKey === 'numberOfPayments'
          ? 'frequency'
          : 'numberOfPayments';
        const secondKeyMatch = filteredPlanList.some((planItem) => planItem.selectedValue[otherKeyMatch] === this.paymentPlanOptionList[selectedPaymentPlanIndex].selectedValue[otherKeyMatch]);
        this.paymentPlanOptionList[selectedPaymentPlanIndex].selectedValue.isItDuplicated = firstKeyMatch && secondKeyMatch;

        if (!this.paymentPlanOptionList[selectedPaymentPlanIndex].selectedValue.isItDuplicated) {
          this.paymentPlanOptionList = this.paymentPlanOptionList.map((planItem) => {
            if (planItem.selectedValue[paymentPlanKey] === this.paymentPlanOptionList[selectedPaymentPlanIndex].selectedValue[paymentPlanKey] || planItem.selectedValue[otherKeyMatch] === this.paymentPlanOptionList[selectedPaymentPlanIndex].selectedValue[otherKeyMatch]) {
              return {
                ...planItem,
                selectedValue: {
                  ...planItem.selectedValue,
                  isItDuplicated: false
                }
              };
            }

            return planItem;
          });
        }
      }
    }
  }

  /**
   * Handles changes in the payment plan deactivation dropdown.
   * @param {DropdownListItem} selectedValue - The selected value for the payment plan deactivation period.
   */
  handlePaymentPlanDeactivationChange(selectedValue: DropdownListItem) {
    this.currentDeactivationPeriodSelected = selectedValue;
  }

  /**
   * Adds a new payment plan to the payment plan list.
   * The new payment plan will be initialized with default values.
   */
  handleAddPaymentPlan() {
    this.paymentPlanOptionList = [...this.paymentPlanOptionList, {
      id: this.paymentPlanOptionList.length + 1,
      numberOfPaymentsList: {...this.discountRuleListDropdown},
      frequencyList: {...this.frequencyListDropdown},
      selectedValue: {
        numberOfPayments: this.discountRuleListDropdown.id as number,
        frequency: this.discountRuleListDropdown.id as number,
        isItDuplicated: false
      }
    }];
  }

  /**
   * Deletes a payment plan from the list based on its ID.
   * After deleting, it also checks and resets any duplicated flags for remaining plans.
   * @param {number} paymentPlanId - The ID of the payment plan to be deleted.
   */
  handleDeletePaymentPlan(paymentPlanId: number): void {
    const paymentPlanItemToBeRemoved = this.paymentPlanOptionList.find((paymentPlan) => paymentPlan.id === paymentPlanId);

    if (!paymentPlanItemToBeRemoved) {
      return;
    }

    this.paymentPlanOptionList = this.paymentPlanOptionList
      .filter(paymentPlan => paymentPlan.id !== paymentPlanId)
      .map(paymentPlan => ({
        ...paymentPlan,
        selectedValue: {
          ...paymentPlan.selectedValue,
          isItDuplicated:
          paymentPlan.selectedValue.numberOfPayments === paymentPlanItemToBeRemoved.selectedValue.numberOfPayments ||
          paymentPlan.selectedValue.frequency === paymentPlanItemToBeRemoved.selectedValue.frequency
            ? false
            : paymentPlan.selectedValue.isItDuplicated
        }
      }));
  }

  /**
   * Retrieves the account ID from the cookies.
   * @returns {number} The account ID, or 0 if no account ID is found.
   */
  getAccountId(): number {
    const accountIdCookie = APP_UTILITIES.getCookie('accountId');
    const accountId = accountIdCookie
      ? Number.parseInt(accountIdCookie)
      : 0;
    return accountId;
  }

  /**
   * Validates the form and payment plan configurations before saving.
   * If the form is valid and there are no duplicated or empty payment plans, it triggers the save action.
   * @returns {Promise<void>} A promise that resolves once the validation and save process is complete.
   */
  async beforeOnSave(): Promise<void> {
    const isFormDiscountValid = this.formObserverRef && (await this.formObserverRef.validate());
    const isAnyPaymentPlanDuplicated = this.paymentPlanOptionList.some((paymentPlanItem) => paymentPlanItem.selectedValue.isItDuplicated);
    const isAnyPaymentPlanEntryEmpty = this.paymentPlanOptionList.some((paymentPlanItem) => paymentPlanItem.selectedValue.numberOfPayments === this.SELECT_TYPE_VALUE || paymentPlanItem.selectedValue.frequency === this.SELECT_TYPE_VALUE);
    const isTheWholeFormValid = isFormDiscountValid && !isAnyPaymentPlanEntryEmpty && !isAnyPaymentPlanDuplicated;
    this.isPaymentPlanUnselected = isAnyPaymentPlanEntryEmpty;

    if (isTheWholeFormValid) {
      const paymentPlan = paymentsModule.paymentPlan;

      if (paymentPlan && paymentPlan != null) {
        this.toggleConfirmationModal();
      }
      else {

        const paymentPlanPayload = {
          accountId: this.getAccountId(),
          minimumOrderAmount: this.minimumOrderAmount,
          disablePaymentsCount: this.disablePaymentsCount,
          disablePaymentsInterval: this.currentDeactivationPeriodSelected.id,
          paymentPlanConfigurations: this.paymentPlanOptionList.map((paymentPlanItem) => ({
            id: null,
            paymentPlanID: null,
            paymentIntervalCount: paymentPlanItem.selectedValue.numberOfPayments,
            paymentInterval: paymentPlanItem.selectedValue.frequency
          }))
        };
        this.onSubmission(paymentsModule.createPaymentPlan(paymentPlanPayload));

      }
    }
  }
}
